import React, { Component } from "react";
import { connect } from "react-redux";
import SortableTree from "react-sortable-tree";
import {
  flowchartActions,
  flowchartSelectors,
  dbActions,
  dbSelectors
} from "../reducers";
import {
  EditorModeEdit,
  ActionDelete,
  ContentAddCircle,
  ActionSearch,
  ContentSave,
  NavigationChevronRight,
  NavigationChevronLeft,
  ContentContentCopy
} from "material-ui/svg-icons";
import {
  Dialog,
  IconButton,
  TextField as TextFld,
  FloatingActionButton,
  FlatButton
} from "material-ui";
import NodeForm from "./NodeForm";
import jsxToString from "jsx-to-string";
import RemoteSubmitButton from "../RemoteSubmitButton";
import {
  DelResponseTooltip,
  AddChildTooltip,
  EditTooltip,
  PrevResultTooltip,
  NextResultTooltip,
  SearchNodesTooltip,
  CollapseFlowchartTooltip,
  ExpandFlowchartTooltip,
  DeleteFlowchartTooltip,
  SaveFlowchartTooltip,
  AddResponseTooltip,
  DuplicateFlowchartTooltip
} from "../utils/Tooltip";


import {
  checkForNodeName,
  createNotification,
  wipeWebhookFlags,
  customSearch,
  revertTree,
  isPhoneNumber,
  removeIds,
  removeFlowchartOptionsIds,
  mapOnTree
} from "../utils/generalUtils";
import { DELIMITER } from "../utils/Constants";
import { urlActions, nodeTemplateActions } from "../reducers";
import TextField from "@material-ui/core/TextField";
import { abilities } from "../utils/Ability";
import Button from "@material-ui/core/Button";
import FlowchartOptions from "./FlowchartOptions";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme } from "@material-ui/core/styles";
import { flowchartStyles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PasswordReset from "../PasswordReset";
import DuplicateForm from "./DuplicateForm";
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import HttpIcon from '@material-ui/icons/Http';
import ReactTooltip from "react-tooltip";

const maxDepth = 100;
const isVirtualized = false;
const treeContainerStyle = {};
const defaultFlowchartOptions = [
  {
    key: "stopReply",
    value:
      "You have successfully been unsubscribed. You will not receive any more messages from this number. Reply START to resubscribe."
  },
  {
    key: "helpReply",
    value: "Reply STOP to unsubscribe. Msg&Data Rates May Apply."
  },
  {
    key: "startReply",
    value:
      "You have successfully been re-subscribed to messages from this number. Reply HELP for help. Reply STOP to unsubscribe. Msg&Data Rates May Apply."
  },
  {
    key: "expirationTime",
    value: "24"
  },
  {
    key: "expireComplete",
    value: "false"
  },
  {
    key: "expireInactive",
    value: "false"
  },
  {
    key: "expirationMessage",
    value: ""
  },
  {
    key: "linkedRepliesDelayFlag",
    value: "false"
  },
  {
    key: "linkedRepliesDelayTime",
    value: "200"
  },
  {
    key: "catchAllResponseFlag",
    value: "false"
  },
  {
    key: "catchAllResponse",
    value: "The response you have provided is invalid. Please try again."
  },
  {
    key: "webhooksIncludePersonalization",
    value: "false"
  }
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  }
});

function setInitValues(node) {
  // slices the hidden input and distributes the replies accordingly
  if (node && node.reply && node.reply.length > 0) {
    node.customerResponse === ""
      ? (node.wildcard = true)
      : (node.wildcard = false);
    if (node.reply.includes(DELIMITER)) {
      var tempReplies = node.reply.split(DELIMITER);
      node.firstReply = tempReplies[0];
      tempReplies = tempReplies.slice(1, tempReplies.length);
      node.replies = [];
      tempReplies.forEach(tempReply => {
        var replyObject = { reply: tempReply };
        node.replies.push(replyObject);
      });
    } else {
      node.firstReply = node.reply;
    }
  }
  return node;
}

const WebhookEnabledStyle = {
  marginLeft: 6,
  marginTop: 11
};

class FlowchartTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNodeDel: false,
      openFlowchartDel: false,
      openFlowchartDup: false,
      openNew: false,
      openEdit: false,
      flowchartDupErrorDisplay: false,
      deleteLoading: true,
      saved: true,
      urlList: [],
      url: 0,
      ability: abilities[this.props.role],
      openDrawer: false,
      flowchartOptions: defaultFlowchartOptions,
      nodeTemplates: null,
      selectedNodeTemplate: null,
      nodeTemplateIndex: undefined,
      webHookUrls: null,

      targetNodeIndex: undefined,
      initialized: false,
      // list of pair of nodes linked by a redirect (id of initial node, name of target node ) 
      portalList: [],
      // list of node names available to be the target of a portal 
      // portal node cannot intersect in any way ( loop avoidance ) and needs a node name 
      availableTarget: []

    };
    this.handleDataTreeChange = this.handleDataTreeChange.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.addGlobalKeyword = this.addGlobalKeyword.bind(this);
    this.saveReservedKeyword = this.saveReservedKeyword.bind(this);

    this.removeGlobalKeyword = this.removeGlobalKeyword.bind(this);

  }

  async componentWillMount() {
    await this.props.fetchFlowcharts();
    //now i have all the trees via this.props.treeData
    //parseTree function is call on load and new flowchart 
    //set up node portal in there

    this.props.fetchUrlsWithRedux();
    this.props.getNodeTemplates();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata) {
      if (
        !this.props.metadata ||
        nextProps.metadata.campaignID !== this.props.metadata.campaignID
      ) {
        this.setState({
          newCampaign: nextProps.metadata.campaignID,
          code: nextProps.metadata.campaignID
            ? nextProps.metadata.campaignID.length <= 6
              ? "short"
              : "long"
            : undefined,
          flowchartOptions: defaultFlowchartOptions
        });
      }

      if (nextProps.metadata.flowchartOptions) {
        this.setState({
          flowchartOptions: nextProps.metadata.flowchartOptions
        });
      }
    }
    if (nextProps.availableTarget) {
      this.setState({ availableTarget: nextProps.availableTarget });
    }
    if (nextProps.urlList) {
      if (nextProps.urlList.urlList[0] && nextProps.urlList.urlList[0].id > 0) {
        this.setState({
          url: 0
        });
        if (this.props.metadata) {
          this.setState({
            newCampaign: nextProps.metadata.campaignID
          });
        }
      }
    }

    if (nextProps.nodeTemplates) {
      this.setState({
        nodeTemplates: nextProps.nodeTemplates
      });
    }

    if (nextProps.treeData) {
      if (nextProps.treeData[0]) {
        if (nextProps.treeData[0].webhookUrlId) {
          this.setState({
            url: nextProps.treeData[0].webhookUrlId
          });
        }
        if (this.props.metadata) {
          this.setState({
            newCampaign: nextProps.metadata.campaignID
          });
        }
      }
    }

    document.body.style.overflow = "auto"; // force overflow auto during password reset
  }

  onChangeFlowchartOptions = (key, value) => {
    let newFlowchartOptions = this.state.flowchartOptions.map(opt => {
      if (opt.key === key) {
        opt.value = value.toString();
        return opt;
      } else {
        return opt;
      }
    });
    this.setState({
      flowchartOptions: newFlowchartOptions
    });
  };

  saveReservedKeyword = (map, stopKeywordWebhook, startKeywordWebhook, helpKeywordWebhook) => {

    var KeywordWebhookMap = map;


    //the key default behavior ignore variable value so it is transfered as a string
    if (typeof KeywordWebhookMap.value === "string") {
      KeywordWebhookMap.value = JSON.parse(KeywordWebhookMap.value)
    }

    //adding a webhook to a keyword in the map
    //check if map already contains keyword being changed 
    var exists = false;

    for (var i in KeywordWebhookMap.value.keywords) {
      //check if map already contains keyword being changed 
      if (KeywordWebhookMap.value.keywords[i]["start"] !== undefined) {
        //update start keyword with the new url 
        KeywordWebhookMap.value.keywords[i]["start"] = startKeywordWebhook; // fetch object with key = keyword and change its value to url
        exists = true;

      }
      else if (KeywordWebhookMap.value.keywords[i]["stop"] !== undefined) {
        //update stop keyword with the new url 
        KeywordWebhookMap.value.keywords[i]["stop"] = stopKeywordWebhook; // fetch object with key = keyword and change its value to url
        exists = true;

      }
      else if (KeywordWebhookMap.value.keywords[i]["help"] !== undefined) {
        //update help keyword with the new url 
        KeywordWebhookMap.value.keywords[i]["help"] = helpKeywordWebhook; // fetch object with key = keyword and change its value to url
        exists = true;

      }

    }


    // if the keyword wasnt found in the map add it 
    if (!exists) {

      KeywordWebhookMap.value.keywords.push(JSON.parse('{"' + "stop" + '":' + JSON.stringify(stopKeywordWebhook) + '}'));
      KeywordWebhookMap.value.keywords.push(JSON.parse('{"' + "start" + '":' + JSON.stringify(startKeywordWebhook) + '}'));
      KeywordWebhookMap.value.keywords.push(JSON.parse('{"' + "help" + '":' + JSON.stringify(helpKeywordWebhook) + '}'));

    }

    var webhookMapExist = false
    //get the index for the webhookmap option
    for (var j in this.state.flowchartOptions) {
      //check if map has been initialize 
      if (this.state.flowchartOptions[j]["key"] === "KeywordWebhookMap") {
        webhookMapExist = true;

      }
    }

    //update webhookmap with webhooks for reserved keywords
    if (!webhookMapExist) {
      this.setState({
        flowchartOptions: [
          ...this.state.flowchartOptions,
          { key: "KeywordWebhookMap", value: KeywordWebhookMap.value } // update the map record 
        ]
      });
    } else {
      this.setState(prevState => ({
        flowchartOptions: (prevState.flowchartOptions.map(
          obj => (obj.key === "KeywordWebhookMap" ? obj = { key: "KeywordWebhookMap", value: KeywordWebhookMap.value } : obj))
        )
      }));
    }
  };

  addGlobalKeyword = (keyword, reply, map, globalKeywordWebhook, isRegex) => {

    const getGlobalKeywordMarker = isRegex ? "globalKeywordRegex|" : "globalKeyword|";
    if (!isRegex) { keyword = keyword.toLowerCase(); }

    let webhookMapIndex = undefined;
    let KeywordWebhookMap = undefined;
    let isKeywordUpdated = false;

    //get the index for the webhookmap option
    for (let j in this.state.flowchartOptions) {
      //check if map already contains keyword being changed (e.value ?) 
      if (this.state.flowchartOptions[j]["key"] === "KeywordWebhookMap") {
        webhookMapIndex = j;
        KeywordWebhookMap = this.state.flowchartOptions[j]

      }
      //check if the keyword exist is in
      if (this.state.flowchartOptions[j].key === getGlobalKeywordMarker + keyword) {
        const currentFlowchartOptions = this.state.flowchartOptions;
        //update the keyword
        currentFlowchartOptions[j].value = reply;
        this.setState({
          flowchartOptions: currentFlowchartOptions
        })

        isKeywordUpdated = true;

      }
    }


    if (KeywordWebhookMap === undefined) {
      KeywordWebhookMap = map;
    }
    //the key default behavior ignore variable value so it is transfered as a string
    if (typeof KeywordWebhookMap.value === "string") {
      KeywordWebhookMap.value = JSON.parse(KeywordWebhookMap.value)
    }

    //adding a webhook to a keyword in the map
    //check if map already contains keyword being changed (e.value ?) 
    let exists = false;

    for (var i in KeywordWebhookMap.value.keywords) {
      //check if map already contains keyword being changed (e.value ?) 
      if (KeywordWebhookMap.value.keywords[i][keyword] !== undefined) {
        //update keyword with the new url 
        KeywordWebhookMap.value.keywords[i] = globalKeywordWebhook; // fetch object with key = keyword and change its value to url
        exists = true;
        break;
      }
    }


    // if the keyword wasnt found in the map add it 
    if (!exists) {

      if (globalKeywordWebhook.url === undefined) {
        globalKeywordWebhook = ""
      }

      const stringToParse = '{' + JSON.stringify(keyword) + ':' + JSON.stringify(globalKeywordWebhook) + '}';
      KeywordWebhookMap.value.keywords.push(JSON.parse(stringToParse));
    }


    if (webhookMapIndex === undefined) {
      this.setState({
        flowchartOptions: [
          ...this.state.flowchartOptions,
          { key: getGlobalKeywordMarker + keyword, value: reply },
          { key: "KeywordWebhookMap", value: KeywordWebhookMap.value } // update the map record 
        ]
      });
    }
    else {
      this.setState(
        prevState => (
          { flowchartOptions: (
            prevState.flowchartOptions
                     .map(
                        obj => (
                          obj.key === "KeywordWebhookMap"
                          ? obj = { key: "KeywordWebhookMap",
                                    value: KeywordWebhookMap.value }
                          : obj))
                     .push(
                        { key: getGlobalKeywordMarker + keyword,
                          value: reply }))}));
      if (!isKeywordUpdated) {
        this.setState({
          flowchartOptions: [
            ...this.state.flowchartOptions,
            { key: getGlobalKeywordMarker + keyword, value: reply },
          ]
        });
      }

    }



  };

  removeGlobalKeyword = keyword => {
    let flowchartOptions = [...this.state.flowchartOptions];
    const index = flowchartOptions.findIndex(opt => opt.key === keyword);
    flowchartOptions.splice(index, 1);

    // remove keyword from map 
    const mapIndex = flowchartOptions.findIndex(opt => opt.key === "KeywordWebhookMap");
    if (mapIndex !== -1 && mapIndex !== undefined) {
      keyword = keyword.substring(keyword.indexOf("|") + 1, keyword.length) // remove from the global keyword globalKeyword|

      if (typeof flowchartOptions[mapIndex].value === 'string' || flowchartOptions[mapIndex].value instanceof String) {
        flowchartOptions[mapIndex].value = JSON.parse(flowchartOptions[mapIndex].value);
      }


      const keywordindex = flowchartOptions[mapIndex].value.keywords.findIndex(
        opt => {
          var stringOpt = JSON.stringify(opt).replaceAll("\"", "");
          return stringOpt.substring(1, stringOpt.indexOf(":")) === keyword;
        }
      );
      if (keywordindex !== -1 && keywordindex !== undefined) {
        flowchartOptions[mapIndex].value.keywords.splice(keywordindex, 1);
      }

    }

    this.setState({
      flowchartOptions: flowchartOptions

    });

  };

  handleOpenNew = rowInfo => this.setState({ openNew: true, rowInfo });
  handleCloseNew = () =>
    this.setState({
      openNew: false,
      rowInfo: null,
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  handleSubmitNewNode = form => {
    let doesNodeExist;

    if (form.name === undefined) {
      doesNodeExist = false;
      form.name = "";
    } else {
      for (var i = 0; i < this.props.treeData.length; i++) {
        doesNodeExist = checkForNodeName(form.name, this.props.treeData[i]);
        if (doesNodeExist) {
          break;
        }
      }
    }

    if (!doesNodeExist) {
      if (this.state.rowInfo) {
        this.props.addNode(form, this.state.rowInfo, this.props.treeData);
      } else {
        this.props.insertLastNode(form, this.props.treeData);
      }
    } else {
      createNotification(
        "Invalid action!",
        "Node name already exists in this flowchart"
      );
    }

    this.handleCloseNew();
    this.setState({
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  };

  handleOpenEdit = rowInfo => this.setState({ openEdit: true, rowInfo });
  handleCloseEdit = () =>
    this.setState({
      openEdit: false,
      rowInfo: null,
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  handleSubmitEdit = form => {
    let doesNodeExist;

    if (
      !this.state.selectedNodeTemplate &&
      (this.state.rowInfo.node.name === undefined ||
        form.name.toLowerCase() ===
        this.state.rowInfo.node.name.toLowerCase() ||
        form.name === "")
    ) {
      doesNodeExist = false;
    } else if (!this.state.selectedNodeTemplate) {
      //check if a node with the same name exist
      for (var i = 0; i < this.props.treeData.length; i++) {
        doesNodeExist = checkForNodeName(form.name, this.props.treeData[i]);
        if (doesNodeExist) {
          break;
        }
      }
    }

    //check if we are updating a node name
    if (this.state.rowInfo.node.name !== form.name) {
      //check if the node being updated is a target node (iterate through tree checking for 
      // and update all the node which aim to that node in this.props.treeData


      //define function to apply on nodes to update target node name 
      var changeTargetNodeName = (node) => {
        //check if the node beeing changed is the target of this node
        if (node && node.targetNode && node.targetNode.name !== "" && node.targetNode.name === this.state.rowInfo.node.name) {
          //if it is, change the target name to the new name
          node.targetNode.name = form.name;

        }
      }

      //for all nodes in tree data , check self and check child 
      mapOnTree(this.props.treeData, false, changeTargetNodeName);

    }


    //if no other node has the same name update the node
    if (!doesNodeExist) {
      this.props.updateNode(form, this.state.rowInfo, this.props.treeData);

    } else {
      createNotification(
        "Invalid action!",
        "Node name already exists in this flowchart"
      );
    }
    this.handleCloseEdit();
    this.setState({
      selectedNodeTemplate: null,
      nodeTemplateIndex: "None"
    });
  };

  handleOpenNodeDel = rowInfo => this.setState({ openNodeDel: true, rowInfo });
  handleCloseNodeDel = () =>
    this.setState({ openNodeDel: false, rowInfo: null });
  handleSubmitNodeDel = () => {
    this.props.deleteNode(this.state.rowInfo, this.props.treeData);
    this.handleCloseNodeDel();
  };

  handleDataTreeChange = event => {
    if (event.searchString == undefined) {
      event.searchString = this.props.searchString;
    }
    this.props.updateTreeData(event);
  };

  handleSubmitNewFlowchart = (
    treeData,
    metadata,
    newCampaign,
    root,
    url,
    flowchartOptions
  ) => {

    var index = flowchartOptions.findIndex(function (item) { return item.key === 'KeywordWebhookMap'; })
    if (index !== undefined && index !== -1) {
      flowchartOptions[index].value = flowchartOptions[index].value;
    }

    let treeDataWithWebHookId = [];

    for (let i = 0; i < treeData.length; i++) {
      treeDataWithWebHookId.push(treeData[i]);
    }

    if (metadata.id === undefined) {
      const jsonRevertTree = revertTree(
        treeDataWithWebHookId,
        metadata,
        newCampaign,
        flowchartOptions
      );


      this.props.postFlowchart(jsonRevertTree);
    } else {
      const jsonRevertTree = revertTree(
        treeDataWithWebHookId,
        metadata,
        newCampaign,
        flowchartOptions,
        root
      );
      this.props.updateFlowchart(jsonRevertTree);
    }
  };

  handleOpenFlowchartDel = rowInfo =>
    this.setState({ openFlowchartDel: true, rowInfo });
  handleCloseFlowchartDel = () =>
    this.setState({ openFlowchartDel: false, rowInfo: null });
  handleSubmitFlowchartDel = () => {
    this.props.deleteFlowchart(this.state.rowInfo);
    this.handleCloseFlowchartDel();
  };

  handleOpenFlowchartDup = () => this.setState({ openFlowchartDup: true });

  handleCloseFlowchartDup = () =>
    this.setState({ openFlowchartDup: false, rowInfo: null, flowchartDupErrorDisplay: false });

  handleSubmitDuplicateFlowchart = (
    e,
    oldCampaignId,
    treeData,
    metadata,
    newCampaign,
    root,
    url,
    flowchartOptions
  ) => {

    let doesFlowchartExist = this.props.flowcharts.find(flowchart => {
      return (
        flowchart.campaignID.toLowerCase() ===
        e.campaignID.toLowerCase()
      );
    });

    if (doesFlowchartExist !== undefined) {
      createNotification(
        "Invalid action!",
        "Flowchart with this Short Code already exists"
      );
      this.handleCloseFlowchartDup();

      return;
    }
    let treeDataWithWebHookId = [];

    var noIdTreeData = removeIds(treeData);
    var noIdFlowchartOption = removeFlowchartOptionsIds(flowchartOptions);
    for (let i = 0; i < noIdTreeData.length; i++) {
      treeDataWithWebHookId.push(noIdTreeData[i]);
    }

    if (treeDataWithWebHookId[0].webhookUrl === 0) {
      let wipedTreeData = [];
      for (let i = 0; i < treeDataWithWebHookId.length; i++) {
        wipedTreeData.push(wipeWebhookFlags(noIdTreeData[i]));
      }
      const jsonRevertTree = revertTree(
        noIdTreeData,
        e,
        e.campaignID,
        noIdFlowchartOption
      );


      this.props.postFlowchart(jsonRevertTree);
    } else {

      const jsonRevertTree = revertTree(
        treeDataWithWebHookId,
        e,
        e.campaignID,
        noIdFlowchartOption,
        root
      );


      this.props.postFlowchart(jsonRevertTree);

    }
    this.handleCloseFlowchartDup();

  }

  handleCampaignChange = event => {
    this.setState({
      newCampaign: event.target.value
    });
  };

  toggleDrawer = () => {
    this.setState({
      openDrawer: !this.state.openDrawer
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  searchFinishCallback = matches => {
    if (this.props.searchFoundCount !== matches.length) {
      this.props.updateSearchProps(matches, this.props.searchFocusIndex);
    }
  };

  generateNodeProps = rowInfo => {
    const sessionStatus = this.props.sessionPath
      ? this.props.sessionPath.find(node => node.id === rowInfo.node.id)
      : null;
    var buttons = [
      <IconButton
        onClick={() => {
          if (
            this.props.treeData.length === 1 &&
            rowInfo.node.id === this.props.treeData[0].id
          ) {
            createNotification(
              "Invalid action!",
              "Cannot delete the last root node (a flowchart must contain at least one node)."
            );
          } else {
            this.handleOpenNodeDel(rowInfo);
          }
        }}
      >
        <ActionDelete data-tip data-for="delete-response-tt" />
        <DelResponseTooltip />
      </IconButton>,
      <IconButton
        onClick={() => this.handleOpenEdit(rowInfo)}>
        <EditorModeEdit data-tip data-for="edit-tt" />
        <EditTooltip />
      </IconButton>
    ];



    if (rowInfo.node.targetNode === undefined || rowInfo.node.targetNode.name === "") {
      buttons.push(
        <IconButton onClick={() => this.handleOpenNew(rowInfo)}>
          <ContentAddCircle data-tip data-for="add-child-tt" />
          <AddChildTooltip />
        </IconButton>)
    }

    //if this node has webhook enable push the hook icon to the list to render 
    if (rowInfo.node.webhookEnabled) {
      buttons.push(
        <HttpIcon
        width="24px"
        height="24px"
        />
      );
    }
    if (rowInfo.node.targetNode && rowInfo.node.targetNode.name !== "") {
      buttons.push(
        <div>
          <LowPriorityIcon
            width="24px"
            height="24px"
            data-tip data-for={"portal-tt" + rowInfo.node.targetNode.name}
          />
          <ReactTooltip id={"portal-tt" + rowInfo.node.targetNode.name} place='right' type='info' effect='solid' className="__react_component_tooltip_custom">
            <span>{"This node will redirect customers to the node named : " + rowInfo.node.targetNode.name}</span>
          </ReactTooltip>
        </div>
      );
    }
    return {
      id: rowInfo.node.id,
      buttons,
      className: !sessionStatus ? null : sessionStatus.status
    };
  };

  render() {
    const {
      treeData,
      searchFocusIndex,
      searchFoundCount,
      searchString,
      collapseAll,
      expandAll,
      updateSearchString,
      prevSearchResult,
      nextSearchResult,
      updateTreeData,
      metadata,
      root,
      availableTarget,
      classes
    } = this.props;

    const SearchButtons = ({ value }) => (
      <span>
        <IconButton
          disabled={!searchFoundCount}
          onClick={() => prevSearchResult(searchFocusIndex, searchFoundCount)}
        >
          <NavigationChevronLeft data-tip data-for="prev-result-tt" />
          <PrevResultTooltip />
        </IconButton>
        <IconButton
          disabled={!searchFoundCount}
          onClick={() => nextSearchResult(searchFocusIndex, searchFoundCount)}
        >
          <NavigationChevronRight data-tip data-for="next-result-tt" />
          <NextResultTooltip />
        </IconButton>
        &nbsp;
        {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
        &nbsp;/&nbsp;
        {searchFoundCount || 0}
      </span>
    );
    const AddResponse = () => (
      <table>
        <tbody>
          <tr>
            <td>
              <span style={{ marginLeft: "20px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ background: munvoOrange }}
                  type="submit"
                  data-tip
                  data-for="add-response-tt"
                  onClick={() => {
                    this.handleOpenNew();
                  }}
                >
                  Add Response
                </Button>
                <AddResponseTooltip />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
    const bottomRight = {
      position: "absolute",
      right: 50,
      bottom: 50
    };
    const FloatingSave = () => (
      <FloatingActionButton
        data-tip
        backgroundColor={munvoBlue}
        data-for="save-tt"
        onClick={() => {
          let doesFlowchartExist = this.props.flowcharts.find(flowchart => {
            return (
              flowchart.campaignID.toLowerCase() ===
              this.state.newCampaign.toLowerCase()
            );
          });
          if (doesFlowchartExist !== undefined && metadata.id === undefined) {
            createNotification(
              "Invalid action!",
              "Flowchart with this Short Code already exists"
            );
          } else if (
            this.state.flowchartOptions.stopReply === "" ||
            this.state.flowchartOptions.helpReply === ""
          ) {
            createNotification(
              "Invalid action!",
              "Reserved Keyword replies cannot be empty."
            );
            this.setState({
              openDrawer: true
            });
          } else {
            this.handleSubmitNewFlowchart(
              treeData,
              metadata,
              this.state.newCampaign,
              root,
              this.state.url,
              this.state.flowchartOptions
            );
            this.props.saveFlowchart();
          }
        }}
      >
        <ContentSave />
        <SaveFlowchartTooltip />
      </FloatingActionButton>
    );

    const FloatingDelete = () => (
      <span>
        {metadata === undefined || metadata.id === undefined ? null : (
          <FloatingActionButton
            data-tip
            backgroundColor={munvoBlue}
            data-for="delete-tt"
            onClick={() => {
              this.handleOpenFlowchartDel(metadata.id);
            }}
          >
            <ActionDelete />
            <DeleteFlowchartTooltip />
          </FloatingActionButton>
        )}
      </span>
    );

    const FloatingDuplicate = () => (
      <span>
        <FloatingActionButton
          data-tip
          backgroundColor={munvoBlue}
          data-for="duplicate-tt"
          onClick={() => {
            this.handleOpenFlowchartDup();
          }}
        >
          <ContentContentCopy />
          <DuplicateFlowchartTooltip />
        </FloatingActionButton>
      </span>
    );

    const ExpandCollapse = () => (
      <div>
        <Button
          color="primary"
          variant="contained"
          style={{ background: munvoOrange }}
          data-tip
          data-for="expand-tt"
          onClick={() => expandAll(treeData)}
        >
          Expand All
        </Button>
        <ExpandFlowchartTooltip />
        &nbsp;&nbsp;&nbsp;
        <Button
          color="primary"
          variant="contained"
          style={{ background: munvoOrange }}
          data-tip
          data-for="collapse-tt"
          onClick={() => collapseAll(treeData)}
        >
          Collapse All
        </Button>
        <CollapseFlowchartTooltip />
      </div>
    );
    const DeleteFlowchartDialog = () => (
      <Dialog
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseNodeDel}
            className={classes.button}
          >
            Cancel
          </Button>,
          <MuiThemeProvider theme={theme}>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleSubmitNodeDel}
              className={classes.button}
            >
              Submit
            </Button>
          </MuiThemeProvider>
        ]}
        open={this.state.openNodeDel}
        onRequestClose={this.handleCloseNodeDel}
      >
        Delete this node and its children?
      </Dialog>
    );



    const DeleteNodeDialog = () => (
      <Dialog
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseFlowchartDel}
            className={classes.button}
          >
            Cancel
          </Button>,
          <MuiThemeProvider theme={theme}>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.handleSubmitFlowchartDel}
              className={classes.button}
            >
              Submit
            </Button>
          </MuiThemeProvider>
        ]}
        open={this.state.openFlowchartDel}
        onRequestClose={this.handleCloseFlowchartDel}
      >
        Delete this flowchart?
      </Dialog>
    );

    const DuplicateDialog = () => (
      <Dialog
        open={this.state.openFlowchartDup}
        title="Duplicate flowchart"
        onRequestClose={this.handleCloseFlowchartDup}
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.handleCloseFlowchartDup}
            labelStyle={{ color: munvoBlue }}
          />,
          <RemoteSubmitButton
            component={FlatButton}
            form="duplicate"
            label="Submit"
            primary={true}
            style={{ color: munvoBlue }}
          />
        ]}
      >
        <div style={{ color: "red" }}>
          {!this.props.isDuplicate && this.state.flowchartDupErrorDisplay ? this.props.duplicateError : ""}
        </div>

        <DuplicateForm
          submitCallback={e => {
            this.handleSubmitDuplicateFlowchart(
              e,
              this.props.metadata.campaignID,
              treeData,
              metadata,
              this.state.newCampaign,
              root,
              this.state.url,
              this.state.flowchartOptions
            );
          }}
        />
      </Dialog>
    );

    const UpdateNodeDialog = () => (
      <Dialog
        title="Edit response"
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseEdit}
            className={classes.button}
          >
            Cancel
          </Button>,
          <RemoteSubmitButton
            component={Button}
            form="node"
            label="Submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          />
        ]}
        open={this.state.openEdit}
        onRequestClose={this.handleCloseEdit}
        autoScrollBodyContent={true}
        contentStyle={dialogStyles}
      >

        <NodeForm
          treeData={treeData}
          availableTarget={this.props.availableTarget}
          webhookList={this.props.urlList.urlList}
          enableReinitialize={true}
          ability={this.state.ability}
          rowInfo={this.state.rowInfo}
          initialValues={
            this.state.selectedNodeTemplate
              ? setInitValues(this.state.selectedNodeTemplate)
              : this.state.rowInfo
                ? setInitValues(this.state.rowInfo.node)
                : null
          }
          submitCallback={this.handleSubmitEdit}
        />

      </Dialog>
    );

    const NodeTemplateDropdown = () => (
      <FormControl>
        <InputLabel htmlFor="nodetemplates">Node Templates</InputLabel>
        <Select
          value={this.state.nodeTemplateIndex}
          native
          onChange={e => {
            if (e.target.value !== "None") {
              const tempNodeTemplate = this.state.nodeTemplates[e.target.value];
              let tempSelectedNodeTemplate = {
                reply: tempNodeTemplate.reply,
                name: tempNodeTemplate.name,
                customerResponse: tempNodeTemplate.customerResponse,
                priority: tempNodeTemplate.priority
              };
              this.setState({
                selectedNodeTemplate: tempSelectedNodeTemplate,
                nodeTemplateIndex: e.target.value
              });
            } else {
              this.setState({
                selectedNodeTemplate: null,
                nodeTemplateIndex: "None"
              });
            }
          }}
          input={<Input id="node-template-list" />} // todo check if it does something 
        >
          <option value={undefined}>None</option>
          {this.state.nodeTemplates.map((nodeTemplate, index) => (
            <option key={index} value={index}>
              {nodeTemplate.nodeTemplateName}
            </option>
          ))}

        </Select>
      </FormControl>
    );



    const NewNodeDialog = () => (
      <Dialog
        title="Add a response"
        actions={[
          <Button
            variant="outlined"
            onClick={this.handleCloseNew}
            className={classes.button}
          >
            Cancel
          </Button>,
          <RemoteSubmitButton
            component={Button}
            form="node"
            label="Submit"
            color="primary"
            variant="outlined"
            className={classes.button}
          />
        ]}
        open={this.state.openNew}
        onRequestClose={this.handleCloseNew}
        autoScrollBodyContent={true}
        contentStyle={dialogStyles}
      >
        <NodeForm
          availableTarget={this.props.availableTarget}
          webhookList={this.props.urlList.urlList}
          enableReinitialize={true}
          ability={this.state.ability}
          initialValues={
            this.state.selectedNodeTemplate
              ? setInitValues(this.state.selectedNodeTemplate, this.state.url)
              : this.state.url
          }
          submitCallback={this.handleSubmitNewNode}
        />

        <NodeTemplateDropdown />
      </Dialog>
    );

    const canDropFunc = ({ nextParent, nextPath }) => {
      //return weither this node can have children
      if (nextParent) {
        return nextParent.canNodeHaveChildren
      }
      return true
    };

    if (treeData.length)
      return (
        <div>
          <section style={{ padding: "1rem 6rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.toggleDrawer}
              style={{ marginRight: "20px", background: munvoOrange }}
            >
              Flowchart Options
            </Button>

            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <FloatingDelete />
            &nbsp;&nbsp;&nbsp;
            <FloatingSave />
            &nbsp;&nbsp;&nbsp;
            <FloatingDuplicate />
            <h1>
              {metadata ? (
                <span>
                  <span>{metadata.name}: </span>
                  <TextFld
                    id={"CampaignNumber"}
                    inputStyle={{ fontSize: "150%" }}
                    underlineStyle={{ width: "75%" }}
                    onChange={this.handleCampaignChange}
                    value={this.state.newCampaign || ""}
                    disabled={true}
                  />
                </span>
              ) : (
                ""
              )}
            </h1>
          </section>
          <section style={{ boxSizing: "border-box" }}>
            <div>
              <div style={{ position: "relative", display: "inline-block" }}>
                <ActionSearch
                  style={{
                    position: "absolute",
                    left: -20,
                    top: 10,
                    width: 20,
                    height: 20
                  }}
                />
                <TextField
                  error={this.props.pathError}
                  helperText={this.props.pathError ? this.props.pathError : null}
                  data-tip
                  data-for="search-tt"
                  style={{ textIndent: 30 }}
                  hinttext="Search"
                  value={searchString || ""}
                  underlinefocusstyle={{ borderColor: munvoBlue }}
                  floatinglabelfocusstyle={{ color: munvoBlue }}
                  onChange={event => {
                    isPhoneNumber(event.target.value)
                      ? this.props.getSessionPath(
                        event.target.value,
                        this.state.newCampaign
                      )
                      : updateSearchString(event.target.value);
                  }}
                />
                <SearchNodesTooltip />
              </div>
              <SearchButtons />
            </div>
            <ExpandCollapse />
            <div style={treeContainerStyle}>
              <SortableTree
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                searchFinishCallback={this.searchFinishCallback}
                searchMethod={props => customSearch(props)}
                treeData={treeData}
                onChange={this.handleDataTreeChange}
                // onMoveNode={logMove}
                maxDepth={maxDepth}
                canDrag={({ node }) => !node.noDragging}
                canDrop={canDropFunc}
                isVirtualized={isVirtualized}
                generateNodeProps={this.generateNodeProps}
                rowHeight={({ node }) => {
                  const subtitle = jsxToString(node.subtitle);
                  const repliesCount = subtitle.match(/Reply:/g).length;
                  if (repliesCount === 1) {
                    return 62;
                  } else {
                    return 62 + repliesCount * 12;
                  }
                }}
              />
            </div>
            <AddResponse />
          </section>
          <DeleteFlowchartDialog />
          <DeleteNodeDialog />
          <DuplicateDialog />
          <NewNodeDialog />
          <UpdateNodeDialog />
          <div style={bottomRight}>
            <FlowchartOptions
              toggleDrawer={this.toggleDrawer}
              flowchartOptions={this.state.flowchartOptions}
              addGlobalKeyword={this.addGlobalKeyword}
              saveReservedKeyword={this.saveReservedKeyword}
              webhookList={this.props.urlList.urlList}
              removeGlobalKeyword={this.removeGlobalKeyword}
              code={this.state.code}
              onChangeFlowchartOptions={this.onChangeFlowchartOptions}
              open={this.state.openDrawer}
              campaignId={this.state.newCampaign}
              flowchartMetadata={this.props.metadata}
            />
          </div>

          <PasswordReset />
        </div>
      );
    else
      return null;
  }
}

const mapStateToProps = state => {
  return {
    treeData: flowchartSelectors.getParsedFlowchart(state) || [],
    searchFocusIndex: flowchartSelectors.getSearchIndex(state),
    searchFoundCount: flowchartSelectors.getSearchCount(state),
    searchString: flowchartSelectors.getSearchString(state),
    metadata: flowchartSelectors.getMetadata(state),
    root: flowchartSelectors.getRoot(state),
    isLoading: state.db.loading,
    flowcharts: dbSelectors.getFlowchartList(state),
    urlList: state.urlList,
    role: state.authentication.role,
    nodeTemplates: state.nodeTemplate.nodeTemplates,
    loadedTree: state.flowcharts.tree,
    sessionPath: state.flowcharts.sessionPath,
    pathError: state.flowcharts.pathError,
    isDuplicate: state.flowcharts.isDuplicate,
    duplicateError: state.flowcharts.duplicateError,
    availableTarget: state.flowcharts.availableTarget
  };
};

var dialogStyles = {
  position: "absolute",
  left: "50%",
  top: "50%",
  height: "80%",
  transform: "translate(-50%, -50%)"
};

FlowchartTree.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  ...urlActions,
  ...flowchartActions,
  ...dbActions,
  ...nodeTemplateActions
})(withStyles(flowchartStyles)(FlowchartTree));

export { setInitValues };
