import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";

import { IconButton } from "material-ui";
import { ActionDelete, ContentAddCircle } from "material-ui/svg-icons";
import Checkbox from "@material-ui/core/Checkbox";
import { Can } from "@casl/react";
import HelpIcon from '@material-ui/icons/Help';

import * as CourierConstants from "../utils/Constants";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import { mapOnTree } from "../utils/generalUtils";
import ReactTooltip from "react-tooltip";

const charLimit = "160";
var reinitialize = false;

const styles = {
  checkbox: {
    color: munvoOrange,
    "&$checked": {
      color: munvoOrange
    }
  },
  checked: {}
};


class CustomField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      length: 0,
      initialized: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //we need to reinitialize the lengths of each component if one of them is deleted
    if (nextProps.reinitialize) {
      this.setState({
        initialized: false
      });
      reinitialize = false;
    }

    if (nextProps.initValue && !this.state.initialized) {
      this.setState({
        length: nextProps.initValue.reply
          ? nextProps.initValue.reply.length
          : nextProps.initValue.length,
        initialized: true
      });
    }
  }

  handleChange(e) {
    this.setState({
      length: e.target.value.length
    });
  }

  render() {
    return (
      <Field
        disabled={this.props.portalMode === "target"}
        component={TextField}
        onChange={this.handleChange}
        className="linkedRepliesTextarea"
        floatingLabelText={
          this.props.label +
          " - Characters: " +
          this.state.length +
          "/" +
          charLimit
        }
        name={this.props.name}
        hintText="Reply with 'YYZ' for more offers!"
        multiLine={true}
        rows={1}
        rowsMax={10}
        underlineStyle={{ borderColor: munvoBlue }}
        underlineFocusStyle={{ borderColor: munvoBlue }}
        floatingLabelFocusStyle={{ color: munvoBlue }}
      />
    );
  }
}

const fieldArrInput = props => {

  return (
    <ul className="linkedRepliesList">
      <CustomField
        initValue={props.initValues ? props.initValues.firstReply : null}
        label="Reply"
        name="firstReply"
        portalMode={props.portalMode}
      />
      <IconButton
        onClick={() => {
          props.fields.push();
        }}
      >
        {" "}
        <ContentAddCircle />{" "}
      </IconButton>
      {props.fields.map((replyLinked, replyIndex) => (
        <li key={replyIndex}>
          <CustomField
            reinitialize={props.reinitialize}
            initValue={
              props.initValues &&
                props.initValues.replies &&
                props.initValues.replies[replyIndex]
                ? props.initValues.replies[replyIndex]
                : null
            }
            label={"Linked Reply #" + (replyIndex + 1)}
            name={`${replyLinked}.reply`}
            portalMode={props.portalMode}
          />
          <IconButton
            onClick={e => {
              reinitialize = true;
              props.fields.remove(replyIndex)(props.initValues && props.initValues.replies && props.initValues.replies[replyIndex] ?
                props.initValues.replies.splice(replyIndex, 1) : null)
            }}
          >
            {" "}
            <ActionDelete />{" "}
          </IconButton>
        </li>
      ))}
    </ul>
  );
};

const renderCheckbox = ({ input, label }) => (
  <Checkbox
    label={label}
    checked={input.value ? true : false}
    value={input.value ? 1 : 0}
    onChange={input.onChange}
  />
);

const WildcardField = ({ input, label }) => (
  <FormControlLabel
    control={
      <Switch
        label={"Wildcard Trigger"}
        checked={input.value ? true : false}
        value={input.value ? 1 : 0}
        onChange={input.onChange}
        color="primary"
      />
    }
    label="Accept any Customer Reply message"
  />
);

const RegexField = ({ input, label, wildcardValue }) => (
  <FormControlLabel
    control={
      <Switch
        label={"Regex Trigger"}
        checked={input.value ? true : false}
        value={input.value ? 1 : 0}
        onChange={input.onChange}
        color="primary"
      />
    }
    label="Use Customer Response as Regular Expression"
    style={{ display: wildcardValue ? "none" : "inline-block" }}
  />
);

class NodeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wildcard: false,
      regex: false,
      initialized: false,
      selectedTarget: this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.targetNode ?
        this.props.rowInfo.node.targetNode.name : "",
      selectedWebhook: { value: "0", key: "0", label: "N/A" },
      portalMode: this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.portalMode ?
        this.props.rowInfo.node.portalMode : ""
    };

    this.toggleWildcard = this.toggleWildcard.bind(this);
    this.toggleRegex = this.toggleRegex.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.handlePortalModeChange = this.handlePortalModeChange.bind(this);
    this.handleWebhookChange = this.handleWebhookChange.bind(this);
    this.canBePortal = this.canBePortal.bind(this);


  }
  canBePortal(props) {

    if (!this || !this.props || !this.props.rowInfo || !this.props.rowInfo.node) {
      return true;
    }

    //check that current node is not a targetnode 
    const currentNodeName = this.props.rowInfo.node.name;

    //if no node name then it cant be a targetnode
    if (currentNodeName === "") {
      return true;
    }

    //define function to filter
    var filter = (node) => {
      //check if any node is targeting this node 
      if (node && node.targetNode && node.targetNode.name === currentNodeName) {
        //if it is, change the target name to the new name
        return node;
      }
    }

    var filteredTree = mapOnTree(props.treeData, false, filter)
    //for all nodes in tree data , check self and check child 
    if (!filteredTree || filteredTree.length === 0 || (filteredTree.length === 1 && filteredTree[0] === undefined)) {
      return true;
    }
    return false;
  }

  handlePortalModeChange(event) {
    this.setState({ portalMode: event.target.value });
  }

  handleTargetChange(event) {
    try
    {
    this.setState({ selectedTarget: JSON.parse(event.target.value).name });
    }
    catch(error)
    {

      //When there is a double quote in the node name
      let json_start_position =  event.target.value.search("\"name\":\"\{")
      let json_end_position = event.target.value.search("}\",\"id\":")

      let before_problem_name = event.target.value.substring(0,json_start_position+"\"name\":\"".length)
      let problem_name = event.target.value.substring(json_start_position+"\"name\":\"".length,json_end_position+"}".length).replaceAll('"','\\\"')
      let after_problem_name = event.target.value.substring(json_end_position+"}".length)

      this.setState({ selectedTarget: JSON.parse(before_problem_name+problem_name+after_problem_name).name });

    }
  }
  handleWebhookChange(event) {
    var tempSelectedWebhook;
    //default no webhook
    tempSelectedWebhook = { value: "0", key: "0", label: "N/A" }
    //find the webhook url with the right id

    for (var i = 0; i < this.props.webhookList.length; i++) {
      if (this.props.webhookList[i].id && this.props.webhookList[i].id == event.target.value) {
        console.log("265, webhook : " + i)
        console.log(JSON.stringify(this.props.webhookList[i]))
        tempSelectedWebhook = { value: event.target.value, key: event.target.value, label: this.props.webhookList[i].url }
        break;
      }
    }

    this.setState({ selectedWebhook: tempSelectedWebhook })
  }

  componentDidMount() {
    //default no webhook
    var tempSelectedWebhook = { value: "0", key: "0", label: "N/A" }

    //find the webhook url with the right id
    if (this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.webhookUrlId) {
      for (var i = 0; i < this.props.webhookList.length; i++) {
        console.log("2285, webhook : " + i)
        console.log(JSON.stringify(this.props.webhookList[i]))
        if (this.props.webhookList[i].id && this.props.webhookList[i].id == this.props.rowInfo.node.webhookUrlId) {
          tempSelectedWebhook = { value: this.props.rowInfo.node.webhookUrlId, key: this.props.rowInfo.node.webhookUrlId, label: this.props.webhookList[i].url }
          break;
        }
      }

    }



    this.setState({
      selectedWebhook: tempSelectedWebhook,
      wildcard: this.props.initialValues.customerResponse === "" ? true : false
    });
  }

  toggleWildcard() {
    this.setState({
      wildcard: !this.state.wildcard
    });
  }

  toggleRegex() {
    this.setState({
      regex: !this.state.regex
    })
  }


  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={TextField}
          floatingLabelText="Name"
          name="name"
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
        />
        <br />

        <Field
          name="wildcard"
          onChange={this.toggleWildcard}
          parse={value => (value ? 1 : 0)}
          component={WildcardField}
          label="wildcard"
        />
        <br />
        <Field
          component={TextField}
          floatingLabelText="Customer Response"
          name="customerResponse"
          hintText="[bcd]at"
          style={{ display: this.state.wildcard ? "none" : "inline-block" }}
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
        />
        <br />
        <Field
          name="useRegex"
          onChange={this.toggleRegex}
          parse={value => (value ? 1 : 0)}
          wildcardValue={this.state.wildcard}
          component={RegexField}
          label="regex"
        />
        <br />
        <Field
          component={TextField}
          floatingLabelText="HiddenReply"
          name="reply"
          style={{ display: "none" }}
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
        />
        <br />
        <FieldArray
          name="replies"
          reinitialize={reinitialize}
          initValues={
            this.props.initialValues.firstReply
              ? this.props.initialValues
              : null
          }
          portalMode={this.state.portalMode}

          component={fieldArrInput}
        />


        <br />




        {/* check if this node has children , if it does grey out since it cant have a portal */}
        <div>
          <label>Redirect customer&nbsp;</label>

          <ReactTooltip id='redirect-tt' place='right' type='info' effect='solid'>
            <span>{"Redirect a customer to a different node. If enabled, this node cannot have children nodes. You cannot redirect to a node which also redirects"}</span>
          </ReactTooltip>
          <HelpIcon data-tip data-for="redirect-tt" style={{ color: munvoBlue }} /><br />
        </div>
        <Field
          style={(this.props.rowInfo && this.props.rowInfo.node && this.props.rowInfo.node.children
            && this.props.rowInfo.node.children.length > 0) || !this.canBePortal(this.props)
            ? { pointerEvents: "none", opacity: "0.4" } : {}}
          name="targetNode"
          component="select"
          value={this.state.selectedTarget}
          onChange={this.handleTargetChange}
        >
          {/* if the node has a target show it as default */}
          {/* if row info is not undefined and target node is not undefined add an option with current target node  */}
          {(this.props.rowInfo && this.props.rowInfo.node.targetNode && this.props.rowInfo.node.targetNode.name)
            &&
            <option key={this.props.rowInfo.node.targetNode.name}
              value={"{\"name\":\"" + this.props.rowInfo.node.targetNode.name + "\",\"id\":\"" + this.props.rowInfo.node.targetNode.id + "\"}"}>
              {this.props.rowInfo.node.targetNode.name}
            </option>}
          {/* either way add an empty option */}
          {<option value={"{\"name\":\"\",\"id\":-1}"}>None</option>}

          {/* generate all the options for target node  */}
          {(this.props.availableTarget !== undefined) && (
            // mapping: for every target node in availableTarget do this
            this.props.availableTarget.map((targetNode) => (
              // check not undefined                 |check no self loop        
              (!this.props.rowInfo || targetNode.name !== this.props.rowInfo.node.name &&
                //check for double appearance of current choice
                (!this.props.rowInfo.node.targetNode || targetNode.name !== this.props.rowInfo.node.targetNode.name)) &&
              //if all passed show this option
              <option key={targetNode.name} value={"{\"name\":\"" + targetNode.name + "\",\"id\":\"" + targetNode.id + "\"}"}>
                {targetNode.name}
              </option>
            )))
          }
        </Field>

        <div>
          {this.state.selectedTarget && this.state.selectedTarget.name !== "" &&
            <div>
              <br />
              <label>Portal option</label>
              <br />
              <div>
                <Field name="portalMode" component="input" type="radio" value="default" onChange={this.handlePortalModeChange} />
                  Default&nbsp;
                <ReactTooltip id='portalDefault-tt' place='right' type='info' effect='solid'>
                  <span>{"The customer will receive both this node's replies and the target node's replies"}</span>
                </ReactTooltip>

                <HelpIcon data-tip data-for="portalDefault-tt" style={{ color: munvoBlue }} />
              </div>
              <div>
                <Field name="portalMode" component="input" type="radio" value="portal" onChange={this.handlePortalModeChange} />
                  Overwrite target reply&nbsp;
                <ReactTooltip id='portalOverwrite-tt' place='right' type='info' effect='solid'>
                  <span>{"The customer will only receive this node's replies, not the target node's replies"}</span>
                </ReactTooltip>

                <HelpIcon data-tip data-for="portalOverwrite-tt" style={{ color: munvoBlue }} />
              </div>

              <div>
                <Field name="portalMode" component="input" type="radio" value="target" onChange={this.handlePortalModeChange} />
                  Send only target reply&nbsp;
                <ReactTooltip id='portalTarget-tt' place='right' type='info' effect='solid'>
                  <span>{"The customer will only receive the target node's replies, this node won't send any replies"}</span>
                </ReactTooltip>

                <HelpIcon data-tip data-for="portalTarget-tt" style={{ color: munvoBlue }} />
              </div>
            </div>
          }
        </div>
        <br />

        <Can I="update" a="webhook" ability={this.props.ability}>
          <label>Webhook &nbsp;</label>
          <br />

          <Field
            name="webhookUrlId"
            component="select"
            value={this.state.selectedWebhook}
            onChange={this.handleWebhookChange}

          >
            {/* select default value   */}
            {(this.props.rowInfo && this.props.rowInfo.node.webhookUrlId
              && this.props.rowInfo.node.webhookUrlId != 0) &&
              <option key={this.props.rowInfo.node.webhookUrlId}
                value={this.props.rowInfo.node.webhookUrlId} label={this.state.selectedWebhook.label} />
            }

            <option key={"0"} value={"0"} label={"N/A"} />
            {this.props.webhookList.map(option => (

              //check for double appearance of current choice
              (!this.props.rowInfo || (!this.props.rowInfo.node.webhookUrlId || option.id != parseInt(this.props.rowInfo.node.webhookUrlId))) &&
              //if all passed show this option
              <option key={option.id} value={option.id} label={option.url + " (" + option.contentType.toUpperCase() + ")"} />

            ))}

          </Field>

        </Can>
        <br />
        <Field
          component={TextField}
          disabled = {this.state.selectedWebhook.label === "N/A" || !this.state.selectedWebhook.label}
          floatingLabelText="Webhook Custom Error Message"
          name="webhookErrorResponse"
          hintText="Some customed error message"
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
          
        />
         <ReactTooltip id='redirect-tt-wf' place='right' type='info' effect='solid'>
            <span>{"SMS sent to the customer in case of a webhook failure"}</span>
          </ReactTooltip>
          <HelpIcon data-tip data-for="redirect-tt-wf" style={{ color: munvoBlue }} />
        <br />
        <Field
          component={TextField}
          floatingLabelText="Node Details"
          name="nodeDetails"
          hintText="additional node related data"
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
        />
        <br />
        <Field
          component={TextField}
          type="number"
          floatingLabelText="Priority"
          name="priority"
          defaultValue={1}
          hintText="Order response priority"
          underlineStyle={{ borderColor: munvoBlue }}
          underlineFocusStyle={{ borderColor: munvoBlue }}
          floatingLabelFocusStyle={{ color: munvoBlue }}
        />
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (
    [
      CourierConstants.COURIER_ARRET,
      CourierConstants.COURIER_OPT_OUT,
      CourierConstants.COURIER_OPTOUT,
      CourierConstants.COURIER_REMOVE,
      CourierConstants.COURIER_TD,
      CourierConstants.COURIER_STOP,
      CourierConstants.COURIER_STOPALL,
      CourierConstants.COURIER_UNSUBSCRIBE,
      CourierConstants.COURIER_CANCEL,
      CourierConstants.COURIER_END,
      CourierConstants.COURIER_QUIT,
      CourierConstants.COURIER_START,
      CourierConstants.COURIER_YES,
      CourierConstants.COURIER_UNSTOP,
      CourierConstants.COURIER_HELP,
      CourierConstants.COURIER_INFO
    ].includes(
      values.customerResponse ? values.customerResponse.toUpperCase() : null
    )
  ) {
    errors.customerResponse =
      "Cannot add a reserved courier keyword to a flowchart. Please set these values in the reserved keywords panel.";
  }


  if (!values.firstReply && values.portalMode !== "target") {
    errors.firstReply = "Required";
  }

  if (values.replies) {
    const repliesArrayErrors = [];
    values.replies.forEach((replyObject, replyIndex) => {
      const replyErrors = {};
      if (!replyObject || !replyObject.reply) {
        replyErrors.reply = "Linked reply cannot be empty";
        repliesArrayErrors[replyIndex] = replyErrors;
      }
    });

    if (repliesArrayErrors.length) {
      errors.replies = repliesArrayErrors;
    }
  }

  return errors;
};

NodeForm = reduxForm({
  form: "node", // a unique identifier for this form,
  validate,
  onSubmit: (values, dispatch, props) => {

    if (values.customerResponse === "" && !values.wildcard) {
      values.customerResponse = null;
    } else if (values.wildcard) {
      values.customerResponse = "";
      values.useRegex = 0;
    }

    //set default value of priority to 1 
    if (!values.priority) {
      values.priority = 1 
    }

    if (values.useRegex === 1 || values.useRegex === true) {
      values.useRegex = true;
    } else {
      values.useRegex = false;
    }

    if (typeof values.targetNode === 'string' || values.targetNode instanceof String) {
      try
      {
        values.targetNode = JSON.parse(values.targetNode);
      }
      catch(error)
      {
        //When there is a double quote in the node name
        let json_start_position =  values.targetNode.search("\"name\":\"\{")
        let json_end_position = values.targetNode.search("}\",\"id\":")

        let before_problem_name = values.targetNode.substring(0,json_start_position+"\"name\":\"".length)
        let problem_name = values.targetNode.substring(json_start_position+"\"name\":\"".length,json_end_position+"}".length).replaceAll('"','\\\"')
        let after_problem_name = values.targetNode.substring(json_end_position+"}".length)

        values.targetNode = JSON.parse(before_problem_name+problem_name+after_problem_name);

      }
    }

    if (values.targetNode === undefined) {
      values.targetNode = JSON.parse("{\"name\":\"\",\"id\":-1}");
    } else {
      if (typeof values.targetNode.id === 'string' || values.targetNode.id instanceof String) {

        values.targetNode.id = parseInt(values.targetNode.id, 10);
      }
    }

    if (values.targetNode.name !== "") {
      //since it has a portal it can no longer have children
      values.canNodeHaveChildren = false;
      if (!values.portalMode) {
        values.portalMode = "default";
      }
      // if (!values.targetNode.id || values.targetNode.id === "undefined") {
      values.targetNode.id = -1;
      //}
    }
    else {
      values.portalMode = "";
      values.canNodeHaveChildren = true;
    }

    //default value is n/a webhook
    if (values.webhookUrlId == null) {
      values.webhookUrlId = "0"
    }
    //if a webhook was selected set webhook enabled to true else to false
    //values.webhookUrlId = parseInt(values.webhookUrlId)
    if (values.webhookUrlId === "0") {
      values.webhookEnabled = 0;
      values.webhookErrorResponse  = null;
    }
    else {
      values.webhookEnabled = 1

    }

    props.submitCallback(values);
  }
})(NodeForm);

export default NodeForm;
